import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./BurgerMenu.css";
import { RemoveStoredToken } from './Login'
import { SetThemeCss } from '../Theme.js'

class BurgerMenu extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
      this.setState({menuOpen: false})
    }
  
    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
      this.setState(state => ({menuOpen: !state.menuOpen}))
    }
  
    render () {
      return (
        <div>
          <Menu 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
          >
            <div className="menu-item" onClick={() => {
                    var currentTheme = localStorage.getItem("theme");
                    if (!currentTheme)
                        currentTheme = 0;
                    localStorage.setItem("theme", 1 - currentTheme);
                    SetThemeCss();
                }}>
                <a>Theme</a>
            </div>

            <div className="menu-item"
                onClick={() => {
                    RemoveStoredToken();
                    window.location.reload();
                }}>
                <a>Logout</a>
            </div>
          </Menu>
        </div>
      )
    }
  }

  export default BurgerMenu